<script setup lang="ts">
import type { Agency, File, JobPosting, WebPage } from '~/types/api'
import { getStructuredDataScript } from '~/utils/structured-data'

interface VPageStructuredDataProps {
    webPage?: WebPage | null | undefined
    agency?: Agency | undefined
    jobPosting?: JobPosting | undefined
    title?: string
    image?: string | File
    type?: string
}

const props = defineProps<VPageStructuredDataProps>()

const runtimeConfig = useRuntimeConfig()
const { $canonicalUrl } = useNuxtApp()
const outputTitle = computed(() => {
    return (
        props.title
        || props.webPage?.title
        || props.agency?.title
        || props.jobPosting?.title
        || runtimeConfig.public.siteName
    )
})

const nuxtImg = useImage()

const img = computed(() => {
    const apiImgPath = props.webPage?.image?.relativePath || (props.image as File)?.relativePath
    const staticImgPath = typeof props.image === 'string' ? props.image : undefined

    const provider = staticImgPath ? 'ipx' : 'interventionRequest'

    if (!(apiImgPath && staticImgPath)) return

    return nuxtImg(apiImgPath || staticImgPath, { width: 1200 }, { provider })
})

// FAQPage data
const questionAnswerBlocks = computed(() => {
    return props.webPage?.contentBlocks?.filter(block => block.type === 'question_answer') || []
})

const isFaqPage = computed(() => !!questionAnswerBlocks.value.length)

const faqMainEntity = computed(() => {
    if (!isFaqPage.value) return

    return questionAnswerBlocks.value.map((block) => {
        return {
            '@type': 'Question',
            'name': block.title,
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': block.text,
            },
        }
    })
})

const structuredDataType = computed(() => {
    return props.type || isFaqPage.value ? 'FAQPage' : 'WebPage'
})

const structuredDataScript = computed(() => {
    return getStructuredDataScript({
        '@context': 'https://schema.org',
        '@type': structuredDataType.value,
        'name': outputTitle.value,
        'image': img.value,
        'url': $canonicalUrl.value,
        'mainEntity': faqMainEntity.value || undefined,
    })
})
</script>

<template>
    <component :is="structuredDataScript" />
</template>
